<template>
    <div>
        <v-row dense>
            <v-col cols="12">
                <VueApexCharts 
                    responsive
                    ref="grafico" 
                    width="100%" 
                    :type="type" 
                    :height="height" 
                    :options="final_options" 
                    :series="series">
                </VueApexCharts>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'
import moment from 'moment'

export default {
    components: {
        VueApexCharts
    },
    props: {
        height: {
            type: Number,
            default: 350
        },
        type: {
            type: String,
            default: "line"
        },
        yrange: {
            type: Array,
            default: null
        },
        xtype: {
            type: String,
            default: "numeric",
        },
        ytype: {
            type: String,
            default: "numeric",
        },
        series: {
            type: Array,
            default: () => ([])
        },
        annotations_x: {
            type: Array,
            default: () => ([])
        },
        annotations_y: {
            type: Array,
            default: () => ([])
        },
        colors: {
            type: Array,
            default: () => ([])
        },
        extra: {
            type: Object,
            default: () => ({})
        }
    },
    computed: {
        final_options() {
            var options = Object.assign({}, this.options, this.extra);
            options.annotations = {
                yaxis: this.annotations_y,
                xaxis: this.annotations_x
            }
            return options;
        }
    },
    data: () => ({
        movement: 0,
        toggle_zoom: 4,
        options: {
            chart: {
                animations: { enabled: false },
                stacked: false,
                height: 350,
                toolbar: { show: false },
                zoom: {
                    type: 'x',
                    enabled: true,
                    autoScaleYaxis: true
                }
            },
            annotations: {
                yaxis: [],
                xaxis: []
            },
            xaxis: {
                type: 'numeric'
            },
            yaxis: {
                labels: { formatter: (val) => val },
                min: 0,
                type: 'numeric'
            },
            legend: {
                show: true,
                fontSize: 15,
                markers: {
                    width: 16,
                    height: 16,
                    radius: 2
                },
                itemMargin: { horizontal: 5 },
                itemOnClick: { toggleDataSeries: false }
            },
            stroke: { width: 2 },
            dataLabels: { enabled: false },
            tooltip: {
                y: {
                    formatter: function (val) {
                        return val
                    }
                }
            },
            responsive: [{
                breakpoints: 500,
                options: {
                    yaxis: {
                        show: false
                    }
                }
            }]
        }
    }),
    methods: {
        update() {
            var _new = {... this.options };
            this.options = this.update_apex(_new);
        },
        update_apex(_new) {
            _new.chart.height = this.height;
            if(this.yrange) {
                _new.yaxis.min = this.yrange[0];
                _new.yaxis.max = this.yrange[1];
            } else {
                delete _new.yaxis.min;
                delete _new.yaxis.max;
            }
            _new.xaxis.type = this.xtype;
            _new.yaxis.type = this.ytype;
            _new.colors = this.colors;
            return _new;
        },
        add_y_line(y1, text, color, text_color) {
            var annotation = {
                y: y1,
                borderColor: color,
                label: {
                    borderColor: color,
                    style: {
                        color: text_color,
                        background: color
                    },
                    text: text
                }
            }

            var _new = { ... this.options };
            _new = Object.assign({}, this.options, this.extra);
            _new = this.update_apex(_new);
            this.options.annotations.yaxis.push(annotation);
            this.options = _new;
        }
    },
    watch: {
        yrange: function() {
            var _new = {... this.options };
            _new = Object.assign({}, this.options, this.extra);
            this.options = this.update_apex(_new);
        },
        xtype: function() {
            var _new = {... this.options };
            _new = Object.assign({}, this.options, this.extra);
            this.options = this.update_apex(_new);
        },
        ytype: function() {
            var _new = {... this.options };
            _new = Object.assign({}, this.options, this.extra);
            this.options = this.update_apex(_new);
        },
        colors: function() {
            var _new = {... this.options };
            _new = Object.assign({}, this.options, this.extra);
            this.options = this.update_apex(_new);
        },
        extra: function() {
            var _new = {... this.options };
            _new = Object.assign({}, this.options, this.extra);
            this.options = this.update_apex(_new);
        }
    }
}
</script>