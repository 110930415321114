<template>
    <v-card outlined elevation="1">
        <template v-if="!!title">
            <v-card-title class="fancy-title">
                <v-icon v-if="!!titleIcon" left>{{ titleIcon }}</v-icon>
                <span class="subtitle">{{ title }}</span>
            </v-card-title>
            <v-divider />
        </template>
        <v-card-text :class="bodyClass">
            <slot></slot>
        </v-card-text>
        <template v-if="actions">
            <v-card-actions :class="[actionClass, actionsAlignClass]">
                <slot name="actions"></slot>
            </v-card-actions>
        </template>
    </v-card>
</template>

<style scoped> 
.v-application .v-card {
    border-radius: 0px;
}

.fancy-title {
    background: linear-gradient(0deg, rgb(245,245,245) 0%, rgb(255,255,255) 100%);
    border-bottom: 1px solid white;
    text-shadow: 0px 1px 0px white;
    color: rgb(80, 80, 80);
    padding: 5px 10px;
}

.v-application .v-card.elevation-1 {
  box-shadow: 0 2px 4px -1px rgba(0,0,0,0.15) !important;
}
</style>

<script>
export default {
    props: ['title-icon', 'title', 'actions', 'actions-align', 'body-class', 'action-class', ],
    computed: {
        actionsAlignClass() {
            if(this.actionsAlign == 'right') return 'justify-end';
            if(this.actionsAlign == 'center') return 'justify-center';
            return '';
        }
    }
}
</script>