<template>
    <DashCard body-class="text-center">
        <div class="py-10 my-10">
            <v-icon :size="150" color="primary">block</v-icon>
            <p class="display-2 primary--text font-weight-thin">Acceso restringido</p>
            <p class="ma-0 text--primary subtitle-1 font-weight-bold">Este contenido no está disponible.</p>
            <p class="ma-0 text--primary">Por favor, contacte al administrador para que pueda habilitar el acceso a este contenido.</p>
        </div>
    </DashCard>
</template>

<script>
import DashCard from '@/shared/components/DashCard'

export default {
    components: {
        DashCard
    }
}
</script>