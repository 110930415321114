<template>
    <div>
        <v-row v-if="!$parent.$parent.get_permission('show_dispatch')">
            <v-col>
                <AccessMessage />
            </v-col>
        </v-row>
        <v-row v-else>
            <v-col cols="12">
                <v-btn class="ma-1" large solo depressed color="secondary" @click="$parent.$parent.reloadComponent()">
                    <v-icon class="mr-2">refresh</v-icon>
                    <span class="subtitle-2">Recargar</span>
                </v-btn>
            </v-col>
            <v-col cols="12">
                <DashCard>
                    <v-skeleton-loader class="pt-2" type="list-item, list-item" :loading="loading">
                        <span class="d-block text--primary title">{{ resumen.estacion }}</span>
                        <span class="d-block subtitle-1">Última observación: {{ resumen.ultimo_tiempo | moment("LL, HH:mm") }}</span>
                        <span class="d-block subtitle-1">Última actualización: {{ now }}</span>
                    </v-skeleton-loader>
                </DashCard>
            </v-col>
            <v-col cols="12" md="8">
                <DashCard title="Concentración MP10 (Promedio Horario)" body-class="pa-0">
                    <v-skeleton-loader class="pa-4" type="image" :loading="loading">
                        <SimpleXYChart ref="horas" type="bar" xtype="datetime" ytype="integer" 
                            :series="series_horario" 
                            :extra="graph_config_v1" 
                            :annotations_y="annotations_mp10" />
                    </v-skeleton-loader>
                </DashCard>
            </v-col>
            <v-col cols="12" md="4">
                <DashCard title="Resumen" body-class="pa-0">
                    <v-skeleton-loader class="pt-2" type="list-item, list-item, list-item, list-item, list-item" :loading="loading">
                        <v-alert :type="resumen.estado_favorable ? 'success' : 'error'" class="ma-3">
                            <template v-if="resumen.estado_favorable">
                                <b>Operación Normal</b>
                                <br/>Viento Favorable
                            </template>
                            <template v-else>
                                <b>Operación dificultuosa</b>
                                <br/>Viento desfavorable
                            </template>
                        </v-alert>
                        <v-divider />
                        <v-simple-table>
                            <template v-slot:default>
                                <tbody>
                                    <tr v-for="(item, i) in resumen.tabla" :key="i">
                                        <td>{{ item.text }}</td>
                                        <td>
                                            {{ item.value | decimal }}
                                            <span v-if="item.var == 'mp10'">µg/m<sup>3</sup></span>
                                            <span v-else-if="item.var == 'dir'">º</span>
                                            <span v-else-if="item.var == 'vel'">m/s</span>
                                        </td>
                                    </tr>
                                </tbody>
                            </template>
                        </v-simple-table>
                    </v-skeleton-loader>
                </DashCard>
            </v-col>
            <v-col cols="12" md="8">
                <DashCard title="Concentración MP10 (Promedio 10 Minutos)" body-class="pa-0">
                    <v-skeleton-loader class="pa-4" type="image" :loading="loading">
                        <SimpleXYChart ref="promedio_minutos" :series="series_minutos" type="line" xtype="datetime" ytype="integer" :extra="graph_config_v1" 
                            :annotations_y="annotations_mp10" />
                    </v-skeleton-loader>
                </DashCard>
            </v-col>
            <v-col cols="12" md="4">
                <DashCard title="Concentración Última Hora" body-class="pa-0">
                    <v-skeleton-loader class="pa-4" type="image" :loading="loading">
                        <SimpleXYChart ref="ultimahora" :series="series_ultimahra" type="line" xtype="datetime" :extra="graph_config_v1"
                            :annotations_y="annotations_mp10" />
                    </v-skeleton-loader>
                </DashCard>
            </v-col>
            <v-col cols="12" md="8">
                <DashCard title="Viento hacia Hotel Mina (Promedio 10 minutos)" body-class="pa-0">
                    <v-skeleton-loader class="pa-4" type="image" :loading="loading">
                        <SimpleXYChart ref="viento" :series="series_viento" type="line" xtype="datetime" 
                            :extra="graph_config_v2" 
                            :annotations_y="annotations_viento" />
                    </v-skeleton-loader>
                </DashCard>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import { axiosHelper, messages } from '@/helpers';
import DashCard from '@/shared/components/DashCard'
import SimpleXYChart from '@/dash/components/SimpleXYChart'
import AccessMessage from '@/dash/components/AccessMessage'
import moment from 'moment'

export default {
    components: {
        DashCard,
        SimpleXYChart,
        AccessMessage
    },
    data: () => ({
        index: {},
        now: moment().format("LL, HH:mm"),
        graph_config: {
            xaxis: {
                labels: {
                    formatter: (val) => moment(val).add('day', 1).format("HH:mm")
                }
            },
            yaxis: {
                type: 'numeric'
            },
            markers: {
                size: 5
            }
        }
    }),
    computed: {
        annotations_mp10() {
            return [
                this.y_line(150, 'ALERTA 1', 'yellow', 'black'), 
                this.y_line(251, 'ALERTA 2', 'orange', 'white'), 
                this.y_line(351, 'ALERTA 3', 'purple', 'white'), 
                this.y_line(501, 'ALERTA 4', 'red', 'white')];
        },
        annotations_viento() {
            return [this.y_line(this.index.valor_alerta_viento, 'Viento desfavorable', 'red', 'white')];
        },
        graph_config_v1() {
            var conf = Object.assign({}, this.graph_config);
            conf.yaxis.min = 0;
            conf.yaxis = Object.assign({}, conf.yaxis);
            conf.yaxis.labels = {
                formatter: (val) => (Math.round(val * 100) / 100) + " ug/m3"
            };
            conf.yaxis.tooltip = {
                y: {
                    formatter: (val) => (Math.round(val * 100) / 100) + " ug/m3"
                }
            };
            conf.colors =[... this.colors_v1];

            return conf;
        },
        graph_config_v2() {
            var conf = Object.assign({}, this.graph_config);
            conf.yaxis = Object.assign({}, conf.yaxis);
            conf.yaxis.labels = {
                formatter: (val) => (Math.round(val * 100) / 100) + " m/s"
            };

            return conf;
        },
        colors_v1() {
            var funct = 
                function({ value, seriesIndex, w }) {
                    if(value >= 501) return '#6A1B9A';
                    if (value >= 350) return '#C62828';
                    if (value >= 250) return '#E64A19';
                    if (value >= 150) return '#FFB300';
                    return '#2E7D32';
                };

            return [funct]
        },
        loading() {
            return !this.index.mp10_grafico_detalles;
        },
        resumen() {
            return this.index.mp10_resumen || {};
        },
        detalles() {
            return this.index.mp10_grafico_detalles || {};
        },
        series_horario() {
            return [{ name: "Concentración MP10", data: (this.index.mp10_horario || []) }];
        },
        series_minutos() {
            return [{ name: "Concentración MP10", data: (this.index.mp10_minutos || []) }];
        },
        series_ultimahra() {
            return [{ name: "Concentración MP10", data: (this.index.ultima_hora || []) }];
        },
        series_viento() {
            return [{ name: "Magnitud de Velocidad del Viento", data: (this.index.mag_viento || []) }];
        }
    },
    mounted() {
        this.$emit('title', { text: "Consola DISPATCH", icon: "track_changes" });

        if (!this.$parent.$parent.get_permission('show_dispatch')) return;
        
        axiosHelper.get('meteodata/consola')
            .then(response => this.index = response.data);
    },
    methods: {
        y_line(y1, text, color, text_color) 
        {
            return {
                y: y1,
                borderColor: color,
                label: {
                    borderColor: color,
                    style: {
                        color: text_color,
                        background: color
                    },
                    text: text
                }
            }
        }
    },
    watch: {
        series_viento: function(_new) {
            if(!_new) return;

            //this.$refs.viento.add_y_line(this.index.valor_alerta_viento, "Viento desfavorable", "red", "#fff");
        },
        series_ultimahra: function(_new) {
            if(!_new) return;

            //this.$refs.ultimahora.add_y_line(this.index.valor_alerta1_mp10, "ALERTA 1", "orange", "#fff");
        },
        series_minutos: function(_new) {
            if(!_new) return;

            //this.$refs.promedio_minutos.add_y_line(this.index.valor_alerta1_mp10, "ALERTA 1", "orange", "#fff");
        },
        series_horario: function(_new) {
            if(!_new) return;

            //this.$refs.horas.add_y_line(this.index.valor_alerta1_mp10, "ALERTA 1", "orange", "#fff");
        }
    }
}
</script>